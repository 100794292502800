import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Table, Card, Input, Button, Pagination, Skeleton, Popconfirm } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import { 
    DeleteMaestraProductosGrowReducer,
    ObtenerMaestraProductosGrowReducer,
} from '../../../Redux/Actions/Administracion/MaestraGrow/MaestraGrow'
import moment from 'moment'
import { funPermisosObtenidos } from '../../../Functions/funPermiso'

const MaestraProductosGrow = (props) => {
    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const [ pageTable, setPageTable ] = useState(1)

    const {
        rex_data_user_permissions
    } = useSelector(({login}) => login)
    
    const getColumnSearchProps = (dataIndex, text) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Buscar ${text}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({ closeDropdown: false })
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined />),
        onFilter: (value, record) => {
            if(dataIndex == 'created_at'){
                let fecha = value.split('/')
                let dia = fecha[0]
                let mes = fecha[1]
                let anio = fecha[2]
                let fecha_comparar = dia ? dia : ''
                fecha_comparar = mes ? mes + '-' + fecha_comparar : fecha_comparar
                fecha_comparar = anio ? anio + '-' + fecha_comparar : fecha_comparar
                return record[dataIndex].toString().toLowerCase().includes(fecha_comparar.toLowerCase())
            }else{
                const text = record[dataIndex] || ''
                return text.toString().toLowerCase().includes(value.toLowerCase())
            }
        }
    })

    const columns = [
        
        {
            title: 'Negocio',
            dataIndex: 'Bussiness',
            ...getColumnSearchProps('Bussiness', 'Negocio'),
            sorter: (a, b) => a.Bussiness ? a.Bussiness.localeCompare(b.Bussiness) : false,
            showSorterTooltip: false,
            render: (Bussiness) => <div title={Bussiness}>{Bussiness}</div>,
            width: '100px',
            fixed: 'left',
        },
        {
            title: 'Código SKU',
            dataIndex: 'Cod_Sku',
            ...getColumnSearchProps('Cod_Sku', 'Código SKU'),
            sorter: (a, b) => a.Cod_Sku ? a.Cod_Sku.localeCompare(b.Cod_Sku) : false,
            showSorterTooltip: false,
            render: (Cod_Sku) => <div title={Cod_Sku}>{Cod_Sku}</div>,
            width: '150px',
            fixed: 'left',
        },
        {
            title: 'Ship SKU',
            dataIndex: 'Ship_Sku',
            ...getColumnSearchProps('Ship_Sku', 'Ship SKU'),
            sorter: (a, b) => a.Ship_Sku ? a.Ship_Sku.localeCompare(b.Ship_Sku) : false,
            showSorterTooltip: false,
            render: (Ship_Sku) => <div title={Ship_Sku}>{Ship_Sku}</div>,
            width: '290px',
            fixed: 'left',
        },
        {
            title: 'Código SKU Hist',
            dataIndex: 'Cod_Sku_Hist',
            ...getColumnSearchProps('Cod_Sku_Hist', 'Código SKU Hist'),
            sorter: (a, b) => a.Cod_Sku_Hist ? a.Cod_Sku_Hist.localeCompare(b.Cod_Sku_Hist) : false,
            showSorterTooltip: false,
            render: (Cod_Sku_Hist) => <div title={Cod_Sku_Hist}>{Cod_Sku_Hist}</div>,
            width: '90px',
        },
        {
            title: 'Categoría',
            dataIndex: 'Category',
            ...getColumnSearchProps('Category', 'Categoría'),
            sorter: (a, b) => a.Category ? a.Category.localeCompare(b.Category) : false,
            showSorterTooltip: false,
            render: (Category) => <div title={Category}>{Category}</div>,
            width: '100px',
        },
        {
            title: 'Sub Categoría',
            dataIndex: 'Sub_Category',
            ...getColumnSearchProps('Sub_Category', 'Sub Categoría'),
            sorter: (a, b) => a.Sub_Category ? a.Sub_Category.localeCompare(b.Sub_Category) : false,
            showSorterTooltip: false,
            render: (Sub_Category) => <div title={Sub_Category}>{Sub_Category}</div>,
            width: '100px',
        },
        {
            title: 'Formato',
            dataIndex: 'Format',
            ...getColumnSearchProps('Format', 'Formato'),
            sorter: (a, b) => a.Format ? a.Format.localeCompare(b.Format) : false,
            showSorterTooltip: false,
            render: (Format) => <div title={Format}>{Format}</div>,
            width: '100px',
        },
        {
            title: 'Familia SKU',
            dataIndex: 'Family_Sku',
            ...getColumnSearchProps('Family_Sku', 'Familia SKU'),
            sorter: (a, b) => a.Family_Sku ? a.Family_Sku.localeCompare(b.Family_Sku) : false,
            showSorterTooltip: false,
            render: (Family_Sku) => <div title={Family_Sku}>{Family_Sku}</div>,
            width: '100px',
        },
        {
            title: 'Marca',
            dataIndex: 'Marca',
            ...getColumnSearchProps('Marca', 'Marca'),
            sorter: (a, b) => a.Marca ? a.Marca.localeCompare(b.Marca) : false,
            showSorterTooltip: false,
            render: (Marca) => <div title={Marca}>{Marca}</div>,
            width: '100px',
        },
        {
            title: 'Clase 1',
            dataIndex: 'Class_1',
            ...getColumnSearchProps('Class_1', 'Clase 1'),
            sorter: (a, b) => a.Class_1 ? a.Class_1.localeCompare(b.Class_1) : false,
            showSorterTooltip: false,
            render: (Class_1) => <div title={Class_1}>{Class_1}</div>,
            width: '90px',
        },
        {
            title: 'Clase 2',
            dataIndex: 'Class_2',
            ...getColumnSearchProps('Class_2', 'Clase 2'),
            sorter: (a, b) => a.Class_2 ? a.Class_2.localeCompare(b.Class_2) : false,
            showSorterTooltip: false,
            render: (Class_2) => <div title={Class_2}>{Class_2}</div>,
            width: '90px',
        },
        {
            title: 'Clase 3',
            dataIndex: 'Class_3',
            ...getColumnSearchProps('Class_3', 'Clase 3'),
            sorter: (a, b) => a.Class_3 ? a.Class_3.localeCompare(b.Class_3) : false,
            showSorterTooltip: false,
            render: (Class_3) => <div title={Class_3}>{Class_3}</div>,
            width: '90px',
        },
        {
            title: 'Clase 4',
            dataIndex: 'Class_4',
            ...getColumnSearchProps('Class_4', 'Clase 4'),
            sorter: (a, b) => a.Class_4 ? a.Class_4.localeCompare(b.Class_4) : false,
            showSorterTooltip: false,
            render: (Class_4) => <div title={Class_4}>{Class_4}</div>,
            width: '90px',
        },
        {
            title: 'Clase 5',
            dataIndex: 'Class_5',
            ...getColumnSearchProps('Class_5', 'Clase 5'),
            sorter: (a, b) => a.Class_5 ? a.Class_5.localeCompare(b.Class_5) : false,
            showSorterTooltip: false,
            render: (Class_5) => <div title={Class_5}>{Class_5}</div>,
            width: '90px',
        },
        {
            title: 'Clase 6',
            dataIndex: 'Class_6',
            ...getColumnSearchProps('Class_6', 'Clase 6'),
            sorter: (a, b) => a.Class_6 ? a.Class_6.localeCompare(b.Class_6) : false,
            showSorterTooltip: false,
            render: (Class_6) => <div title={Class_6}>{Class_6}</div>,
            width: '90px',
        },
       
    ]

    const ObtenerProductosGrow = async () => {
        await dispatch(ObtenerMaestraProductosGrowReducer())
    }

    const {
        rex_data_maestra_productos_grow,
        rex_cargando_maestra_productos_grow,
        rex_feha_actualizacion_maestra_productos_grow
    } = useSelector(({maestraGrow}) => maestraGrow)


    useEffect(() => {
        if(rex_data_maestra_productos_grow.length == 0){
            ObtenerProductosGrow()
        }
    }, [])

    return (

        <div 
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card 
                    title={
                        <Title className="Title-Module">
                        Maestra de Productos Grow {rex_feha_actualizacion_maestra_productos_grow?.espfechaactualizacion
                            ? <span style={{fontSize: '12px'}}>{moment(rex_feha_actualizacion_maestra_productos_grow.espfechaactualizacion).format('DD/MM/YYYY hh:mm:a')}</span>
                            : <span style={{fontSize: '12px'}}>(31/03/2025 11:40 AM)</span>
                        }
                        </Title>}
                    className="Card-Item"
                >
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px'}}>
                        <Button
                            style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            onClick={()=> {
                                ObtenerProductosGrow()
                            }}    
                        >
                            <ReloadOutlined
                                style={{fontSize:'14px', marginRight: '4px',color:'#1677ff',}}
                            />Recargar
                        </Button>
                        { rex_cargando_maestra_productos_grow && rex_data_maestra_productos_grow.length == 0
                            ? <Skeleton.Input active={true} size='default'/>
                            : <div style={{display:'flex', columnGap: '10px', alignItems: 'center'}}>
                                <Pagination
                                    current={pageTable}
                                    total={rex_data_maestra_productos_grow.length}
                                    pageSize={15}
                                    onChange={(page) => setPageTable(page)}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_maestra_productos_grow.length )} registros</div>
                            </div>
                        }
                        
                    </div>

                    { rex_cargando_maestra_productos_grow && rex_data_maestra_productos_grow.length == 0
                    ? <div style={{marginTop:'10px'}}>
                        <Skeleton/>
                        <Skeleton/>
                    </div>
                        
                    : <Table 
                        pagination={{
                            position: ["none", "none"],
                            pageSize: 20,
                            current: pageTable,
                        }}
                        scroll={{
                            x: '1000px',
                            y: '450px',
                        }}
                        columns={columns.filter(cls => {
                            if(cls.title == "Acciones"){
                                const response_perms = funPermisosObtenidos(rex_data_user_permissions, 'master.products', cls)

                                if(response_perms){
                                    return true
                                }

                                return false 
                            }

                            return true
                        })}
                        dataSource={rex_data_maestra_productos_grow} 
                        className="Table-Home"
                        size='small'
                    />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default MaestraProductosGrow