import React, { useEffect } from 'react'
import { Row, Col, Typography, Card, Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import CardUploadFile from '../../Components/UploadFile/CardUploadFile'
import CardNotification from '../../Components/UploadFile/CardNotification'
import {
    CargarManualReducer,
    CargarSellinReducer,
    CargarClientesReducer,
    CargarMaestraPreciosReducer,
    CargarProductosReducer,
    CargarArchivoS3Reducer,
    UploadSellinGrowReducer,
    ValidateDtManualesDistributorsReducer,
    UploadCargarMaestraPreciosReducer,
    UploadHomologacionComboReducer,
    UploadHomologacionNoComboReducer,
    // CargarNoHMLReducer
} from '../../Redux/Actions/CargarData/CargarData'
import '../../Styles/Routes/UploadFile/UploadFile.css'
import { funPermisosObtenidos } from '../../Functions/funPermiso'
import { useNavigate } from 'react-router-dom'
import {
    ArrowLeftOutlined
} from '@ant-design/icons'
import { GetUploadDataRestriction } from '../../Redux/Actions/Administracion/Administrador/UploadDataRestriction'
import { GetDataTemplateDownloadFiles } from '../../Redux/Actions/Administracion/Administrador/DescargasArchivos'

const UploadFile = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetUploadDataRestriction())
        dispatch(GetDataTemplateDownloadFiles())
    }, [])

    const {
        rex_data_user_permissions
    } = useSelector(({ login }) => login)

    const {
        rex_get_upload_data_restriction,
    } = useSelector(({ uploadDataRestriction }) => uploadDataRestriction)

    const {
        rex_data_template_descargas_archivos,
    } = useSelector(({ descargasArchivos }) => descargasArchivos)

    const data = [     
        //{ key: 1, titleCard: 'Homologacion Combo', textCard: '', action: UploadHomologacionComboReducer, slug: 'cargaarchivos.homologacioncombo', extraAction: null },
        { key: 2, titleCard: 'Homologacion No Combo', textCard: '', action: UploadHomologacionNoComboReducer, slug: 'cargaarchivos.homologacionnocombo', extraAction: null },
        { key: 3, titleCard: 'Master de Producto', textCard: '', action: CargarProductosReducer, slug: 'cargaarchivos.masterproductos', extraAction: null },
        { key: 4, titleCard: 'Master de Clientes', textCard: '', action: CargarClientesReducer, slug: 'cargaarchivos.masterclientes', extraAction: null },
        //{ key: 5, titleCard: 'Master de Precios', textCard: '', action: UploadCargarMaestraPreciosReducer, slug: 'cargaarchivos.masterprecios', extraAction: null },
        // {key: 5, titleCard: 'Master de Precios', textCard: '', action: CargarMaestraPreciosReducer, slug: 'cargaarchivos.masterprecios', extraAction: null}, // api para cargar maestra original
        // {key: 6, titleCard: 'Bonificaciones SO', textCard: '(Plantilla)', action: ''},
        // {key: 0, titleCard: 'Archivo Plano SO', textCard: '(Plantilla)', action: CargarArchivoS3Reducer, slug: 'cargaarchivos.archivoplanoso'},
        // {key: 0, titleCard: 'Archivo Plano SO', textCard: '(Plantilla)', action: CargarManualReducer, slug: 'cargaarchivos.archivoplanoso', extraAction: ValidateDtManualesDistributorsReducer}, // api archivo plano so
        // {key: 7, titleCard: 'Productos No HML', textCard: '', action: CargarNoHMLReducer, slug: 'cargaarchivos.nohml'},
        // {key: 1, titleCard: 'Sell In Thanos', textCard: '(Mes Actual)', action: CargarArchivoS3Reducer, slug: 'cargaarchivos.sellinthanos'},
        // {key: 2, titleCard: 'Sell Out', textCard: '(Cuota)', action: CargarArchivoS3Reducer, slug: 'cargaarchivos.sellout', extraAction: null}, // api sell out
        // {key: 3, titleCard: 'Master de Producto', textCard: '', action: CargarProductosReducer, slug: 'cargaarchivos.masterproductos'},
        // {key: 4, titleCard: 'Master de Clientes', textCard: '', action: CargarArchivoS3Reducer, slug: 'cargaarchivos.masterclientes'},
        // {key: 5, titleCard: 'Master de Precios', textCard: '', action: CargarArchivoS3Reducer, slug: 'cargaarchivos.masterprecios'},
        // {key: 6, titleCard: 'Bonificaciones SO', textCard: '(Plantilla)', action: ''},
        // {key: 0, titleCard: 'Archivo Plano SO', textCard: '(Plantilla)', action: CargarManualReducer, slug: 'cargaarchivos.archivoplanoso'},
        // {key: 1, titleCard: 'Sell In Thanos', textCard: '(Mes Actual)', action: UploadSellinGrowReducer, slug: 'cargaarchivos.sellinthanos', extraAction: null}, // Esta es la api sell in
        // {key: 1, titleCard: 'Sell In Thanos', textCard: '(Mes Actual)', action: CargarSellinReducer, slug: 'cargaarchivos.sellinthanos'},
        // {key: 2, titleCard: 'Sell Out', textCard: '(Cuota)', action: '', slug: 'cargaarchivos.sellout'},
    ]

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                    transition: 'width .7s'
                }}
            >
                <Card className="Card-Container">
                    <Card
                        title={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Button type='primary' size='small' ghost onClick={() => navigate(-1)} style={{ marginRight: '10px' }}>
                                    <ArrowLeftOutlined />
                                </Button>
                                <Title
                                    className="Title-Module"
                                    level={4}
                                    style={{ marginBottom: 0 }}
                                >
                                    Upload Data
                                </Title>
                            </div>
                        }
                        className="Card-Item"
                    >
                        <Row style={{ padding: '0' }}>
                            <Col span={18}>
                                <Row gutter={15} style={{ margin: '0', rowGap: '20px' }}>
                                    {
                                        data.map((dt) => {
                                            return (
                                                funPermisosObtenidos(
                                                    rex_data_user_permissions, dt.slug,
                                                    <Col className="gutter-row" xl={6} lg={12} sm={12} xs={24} key={dt.key}>
                                                            <CardUploadFile
                                                                titleCard={dt.titleCard}
                                                                textCard={dt.textCard}
                                                                action={dt.action}
                                                                extraAction={dt.extraAction}
                                                                uploaddatarestriction={rex_get_upload_data_restriction}
                                                                data_template_descargas_archivos={rex_data_template_descargas_archivos}
                                                            />
                                                    </Col>
                                                )
                                            )
                                        })
                                    }
                                </Row>
                            </Col>
                            <Col span={6}>
                                <CardNotification />
                            </Col>
                        </Row>
                    </Card>
                </Card>
            </div>
        </>
    )
}

export default UploadFile
