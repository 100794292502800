import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import {
    DeleteMaestraClientesGrowReducer,
    ObtenerMaestraClientesGrowReducer
} from '../../../Redux/Actions/Administracion/MaestraGrow/MaestraGrow'
import { Typography, Table, Card, Input, Button, Pagination, Skeleton, Popconfirm } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import { funPermisosObtenidos } from '../../../Functions/funPermiso'

const MaestraClientesGrow = (props) => {

    const collapsed = props.collapsed

    const { Title } = Typography
    const dispatch = useDispatch()
    const [pageTable, setPageTable] = useState(1)

    const {
        rex_data_user_permissions
    } = useSelector(({ login }) => login)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({ closeDropdown: false })
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined />),
        onFilter: (value, record) => {
            if (dataIndex == 'created_at') {
                let fecha = value.split('/')
                let dia = fecha[0]
                let mes = fecha[1]
                let anio = fecha[2]
                let fecha_comparar = dia ? dia : ''
                fecha_comparar = mes ? mes + '-' + fecha_comparar : fecha_comparar
                fecha_comparar = anio ? anio + '-' + fecha_comparar : fecha_comparar
                return record[dataIndex].toString().toLowerCase().includes(fecha_comparar.toLowerCase())
            } else {
                const text = record[dataIndex] || ''
                return text.toString().toLowerCase().includes(value.toLowerCase())
            }
        }
    })

    const columns = [
        { title: 'Business',fixed: 'left', dataIndex: 'Business', ...getColumnSearchProps('Business', 'Ship-Business'), sorter: (a, b) => a.Business?.localeCompare(b.Business), showSorterTooltip: false, render: (value) => <div title={value}>{value}</div>, width: '10px' },
       { title: 'Ship-To',fixed: 'left', dataIndex: 'Ship_To', ...getColumnSearchProps('Ship_To', 'Ship-To'), sorter: (a, b) => a.Ship_To?.localeCompare(b.Ship_To), showSorterTooltip: false, render: (value) => <div title={value}>{value}</div>, width: '10px' },
        { title: 'Cliente HML', dataIndex: 'Hml_Client', ...getColumnSearchProps('Hml_Client', 'Cliente HML'), sorter: (a, b) => a.Hml_Client?.localeCompare(b.Hml_Client), showSorterTooltip: false, render: (value) => <div title={value}>{value}</div>, width: '10px' },
        { title: 'Hml_Subsidiary', dataIndex: 'Hml_Subsidiary', ...getColumnSearchProps('Hml_Subsidiary', 'Hml_Subsidiary'), sorter: (a, b) => a.Hml_Subsidiary?.localeCompare(b.Hml_Subsidiary), showSorterTooltip: false, render: (value) => <div title={value}>{value}</div>, width: '10px' },
        { title: 'Channel', dataIndex: 'Channel', ...getColumnSearchProps('Channel', 'Channel'), sorter: (a, b) => a.Channel?.localeCompare(b.Channel), showSorterTooltip: false, render: (value) => <div title={value}>{value}</div>, width: '10px' },

    ]

    const {
        rex_data_maestra_clientes_grow,
        rex_cargando_maestra_clientes_grow,
        rex_feha_actualizacion_maestra_clientes_grow
    } = useSelector(({ maestraGrow }) => maestraGrow)

    const ObtenerClientesGrow = async () => {
        await dispatch(ObtenerMaestraClientesGrowReducer())
    }

    useEffect(() => {
        if (rex_data_maestra_clientes_grow.length == 0) {
            ObtenerClientesGrow()
        }
    }, [])

    return (

        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card
                    title={
                        <Title className="Title-Module">
                            Maestra de Clientes Grow {rex_feha_actualizacion_maestra_clientes_grow?.espfechaactualizacion
                                ? <span style={{ fontSize: '12px' }}>{moment(rex_feha_actualizacion_maestra_clientes_grow.espfechaactualizacion).format('DD/MM/YYYY hh:mm:a')}</span>
                                : <span style={{ fontSize: '12px' }}>(31/03/2025 11:40 AM)</span>
                            }
                        </Title>
                    }
                    className="Card-Item"
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                        <Button
                            style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }} size='small'
                            onClick={() => {
                                ObtenerClientesGrow()
                            }}
                        >
                            <ReloadOutlined
                                style={{ fontSize: '14px', marginRight: '4px', color: '#1677ff', }}
                            />Recargar
                        </Button>
                        {rex_cargando_maestra_clientes_grow && rex_data_maestra_clientes_grow.length == 0
                            ? <Skeleton.Input active={true} size='default' />
                            : <div style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
                                <Pagination
                                    current={pageTable}
                                    total={rex_data_maestra_clientes_grow.length}
                                    pageSize={15}
                                    onChange={(page) => setPageTable(page)}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_maestra_clientes_grow.length)} registros</div>
                            </div>
                        }

                    </div>
                    {rex_cargando_maestra_clientes_grow && rex_data_maestra_clientes_grow.length == 0
                        ? <div style={{ marginTop: '10px' }}>
                            <Skeleton />
                            <Skeleton />
                        </div>
                        : <Table
                            pagination={{
                                position: ["none", "none"],
                                pageSize: 20,
                                current: pageTable
                            }}
                            scroll={{
                                x: '1000px',
                                y: '450px',
                            }}
                            columns={columns.filter(cls => {
                                if (cls.title == "Acciones") {
                                    const response_perms = funPermisosObtenidos(rex_data_user_permissions, 'master.clients', cls)

                                    if (response_perms) {
                                        return true
                                    }

                                    return false
                                }

                                return true
                            })}
                            dataSource={rex_data_maestra_clientes_grow}
                            className="Table-Home"
                            size='small'
                        />
                    }
                </Card>
            </Card>
        </div>
    )
}

export default MaestraClientesGrow