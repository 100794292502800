import React, { useEffect, useState } from 'react'
import { Modal, Table, Input, Select } from 'antd'
import {
    MenuOutlined,
} from '@ant-design/icons'
import { AsignarFiltrosMaestraProductosReducer, FiltrarMaestraProductosReducer, FiltrosDataMasterProductos } from '../../../Redux/Actions/Administracion/MaestraProductos/MaestraProductos'
import { useDispatch, useSelector } from 'react-redux'
import { SelectSkuReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'
import { ObtenerMasterProductosReducer, SelectProductsNoHomologacionReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/NoHomologados'

const ModalSkuNoHomologaciones = (props) => {



    const aceptar = props.aceptar
    const abrirModalSku = props.abrirModalSku
    const setAbrirModalSku = props.setAbrirModalSku
    const rex_data_maestra_productos = props.rex_data_master_productos
    const idRow = props.idRow
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const dispatch = useDispatch()



    const {
        rex_filtro_data_maestra_codigo,
        rex_filtro_data_maestra_nombre,
        rex_filtro_data_maestra_categoria,
        rex_data_maestra_productos_copia
    } = useSelector(({ maestraProductos }) => maestraProductos)

    const [txtSegmento, setTxtSegmento] = useState('')
    const [txtNombre, setTxtNombre] = useState('')

    useEffect(() => {
        console.log('ObtenerMasterProductosReducer')
        // dispatch(ObtenerMasterProductosReducer());
    }, []); // Se ejecuta solo una vez al montar el componente



    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({ closeDropdown: false })
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<MenuOutlined />),
        onFilter: (value, record) => {
            const text = record[dataIndex] || ''
            return text.toString().toLowerCase().includes(value.toLowerCase())
        }
    })
    const columns = [
        
        {
            title: 'Negocio',
            dataIndex: 'Bussiness',
            ...getColumnSearchProps('Bussiness', 'Negocio'),
            sorter: (a, b) => a.Bussiness ? a.Bussiness.localeCompare(b.Bussiness) : false,
            showSorterTooltip: false,
            render: (Bussiness) => <div title={Bussiness}>{Bussiness}</div>,
            width: '100px',
            fixed: 'left',
        },
        {
            title: 'Código SKU',
            dataIndex: 'Cod_Sku',
            ...getColumnSearchProps('Cod_Sku', 'Código SKU'),
            sorter: (a, b) => a.Cod_Sku ? a.Cod_Sku.localeCompare(b.Cod_Sku) : false,
            showSorterTooltip: false,
            render: (Cod_Sku) => <div title={Cod_Sku}>{Cod_Sku}</div>,
            width: '150px',
            fixed: 'left',
        },
        {
            title: 'Ship SKU',
            dataIndex: 'Ship_Sku',
            ...getColumnSearchProps('Ship_Sku', 'Ship SKU'),
            sorter: (a, b) => a.Ship_Sku ? a.Ship_Sku.localeCompare(b.Ship_Sku) : false,
            showSorterTooltip: false,
            render: (Ship_Sku) => <div title={Ship_Sku}>{Ship_Sku}</div>,
            width: '290px',
            fixed: 'left',
        },
        {
            title: 'Código SKU Hist',
            dataIndex: 'Cod_Sku_Hist',
            ...getColumnSearchProps('Cod_Sku_Hist', 'Código SKU Hist'),
            sorter: (a, b) => a.Cod_Sku_Hist ? a.Cod_Sku_Hist.localeCompare(b.Cod_Sku_Hist) : false,
            showSorterTooltip: false,
            render: (Cod_Sku_Hist) => <div title={Cod_Sku_Hist}>{Cod_Sku_Hist}</div>,
            width: '90px',
        },
        {
            title: 'Categoría',
            dataIndex: 'Category',
            ...getColumnSearchProps('Category', 'Categoría'),
            sorter: (a, b) => a.Category ? a.Category.localeCompare(b.Category) : false,
            showSorterTooltip: false,
            render: (Category) => <div title={Category}>{Category}</div>,
            width: '100px',
        },
        {
            title: 'Sub Categoría',
            dataIndex: 'Sub_Category',
            ...getColumnSearchProps('Sub_Category', 'Sub Categoría'),
            sorter: (a, b) => a.Sub_Category ? a.Sub_Category.localeCompare(b.Sub_Category) : false,
            showSorterTooltip: false,
            render: (Sub_Category) => <div title={Sub_Category}>{Sub_Category}</div>,
            width: '100px',
        },
        {
            title: 'Formato',
            dataIndex: 'Format',
            ...getColumnSearchProps('Format', 'Formato'),
            sorter: (a, b) => a.Format ? a.Format.localeCompare(b.Format) : false,
            showSorterTooltip: false,
            render: (Format) => <div title={Format}>{Format}</div>,
            width: '100px',
        },
        {
            title: 'Familia SKU',
            dataIndex: 'Family_Sku',
            ...getColumnSearchProps('Family_Sku', 'Familia SKU'),
            sorter: (a, b) => a.Family_Sku ? a.Family_Sku.localeCompare(b.Family_Sku) : false,
            showSorterTooltip: false,
            render: (Family_Sku) => <div title={Family_Sku}>{Family_Sku}</div>,
            width: '100px',
        },
        {
            title: 'Marca',
            dataIndex: 'Marca',
            ...getColumnSearchProps('Marca', 'Marca'),
            sorter: (a, b) => a.Marca ? a.Marca.localeCompare(b.Marca) : false,
            showSorterTooltip: false,
            render: (Marca) => <div title={Marca}>{Marca}</div>,
            width: '100px',
        },
        {
            title: 'Clase 1',
            dataIndex: 'Class_1',
            ...getColumnSearchProps('Class_1', 'Clase 1'),
            sorter: (a, b) => a.Class_1 ? a.Class_1.localeCompare(b.Class_1) : false,
            showSorterTooltip: false,
            render: (Class_1) => <div title={Class_1}>{Class_1}</div>,
            width: '90px',
        },
        {
            title: 'Clase 2',
            dataIndex: 'Class_2',
            ...getColumnSearchProps('Class_2', 'Clase 2'),
            sorter: (a, b) => a.Class_2 ? a.Class_2.localeCompare(b.Class_2) : false,
            showSorterTooltip: false,
            render: (Class_2) => <div title={Class_2}>{Class_2}</div>,
            width: '90px',
        },
        {
            title: 'Clase 3',
            dataIndex: 'Class_3',
            ...getColumnSearchProps('Class_3', 'Clase 3'),
            sorter: (a, b) => a.Class_3 ? a.Class_3.localeCompare(b.Class_3) : false,
            showSorterTooltip: false,
            render: (Class_3) => <div title={Class_3}>{Class_3}</div>,
            width: '90px',
        },
        {
            title: 'Clase 4',
            dataIndex: 'Class_4',
            ...getColumnSearchProps('Class_4', 'Clase 4'),
            sorter: (a, b) => a.Class_4 ? a.Class_4.localeCompare(b.Class_4) : false,
            showSorterTooltip: false,
            render: (Class_4) => <div title={Class_4}>{Class_4}</div>,
            width: '90px',
        },
        {
            title: 'Clase 5',
            dataIndex: 'Class_5',
            ...getColumnSearchProps('Class_5', 'Clase 5'),
            sorter: (a, b) => a.Class_5 ? a.Class_5.localeCompare(b.Class_5) : false,
            showSorterTooltip: false,
            render: (Class_5) => <div title={Class_5}>{Class_5}</div>,
            width: '90px',
        },
        {
            title: 'Clase 6',
            dataIndex: 'Class_6',
            ...getColumnSearchProps('Class_6', 'Clase 6'),
            sorter: (a, b) => a.Class_6 ? a.Class_6.localeCompare(b.Class_6) : false,
            showSorterTooltip: false,
            render: (Class_6) => <div title={Class_6}>{Class_6}</div>,
            width: '90px',
        },
       
    ]
    const seleccionarFila = (record) => {
        return {
            onClick: event => {
                dispatch(SelectProductsNoHomologacionReducer(record))
                setSelectedRowKeys([record.id])
                setAbrirModalSku(false)
                dispatch(FiltrarMaestraProductosReducer('', txtNombre));
            }
        }
    }
    const rowClassName = (record) => {
        return selectedRowKeys == JSON.stringify(record.id) ? 'Row-Active' : ''
    }



    return (
        <Modal
            title="Maestra de Productos"
            open={idRow == abrirModalSku ? true : false}
            onCancel={() => {
                setAbrirModalSku(null)
                setTxtNombre('')
                setTxtSegmento('')

            }}
            onOk={() => {
                aceptar()
                setAbrirModalSku(null)
                setTxtNombre('')
                setTxtSegmento('')

            }}
            centered
            closable={false}
            width={1000}
        >
            {/* 
             <button onClick={()=>{
                console.log('p1'+JSON.stringify(rex_data_maestra_productos_copia))
                console.log('p2'+JSON.stringify(rex_data_maestra_productos))

            }}></button>
            */}

            <Select
                showSearch
                placeholder="Codigo Sku"
                optionFilterProp="children"
                allowClear={true}
                onClear={() => {
                    setTxtSegmento('')
                    dispatch(FiltrarMaestraProductosReducer('', txtNombre)); // Restaurar datos originales
                }}
                onSelect={(value) => {
                    setTxtSegmento(value)
                    dispatch(FiltrarMaestraProductosReducer(value, txtNombre))

                }}
                onSearch={(value) => {
                    setTxtSegmento(value)
                    dispatch(FiltrarMaestraProductosReducer(value, txtNombre))
                }}
                style={{ marginLeft: '4px', width: '150px' }}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                } w
                options={txtNombre.length + txtSegmento.length == 0
                    ? rex_filtro_data_maestra_codigo
                    : rex_filtro_data_maestra_codigo
                        .filter(rex => rex_data_maestra_productos
                            .find((ma) => ma.cod_sku ? ma.cod_sku.toUpperCase() == rex.value : false))}
            />
            <Select
                showSearch
                placeholder="Ship Sku"
                optionFilterProp="children"
                allowClear={true}
                onClear={() => {
                    setTxtNombre('')
                    dispatch(FiltrarMaestraProductosReducer(txtSegmento, '')); // Restaurar datos originales
                }}
                onSelect={(value) => {
                    setTxtNombre(value)
                    dispatch(FiltrarMaestraProductosReducer(txtSegmento, value)); // Restaurar datos originales
                }}
                onSearch={(value) => {
                    setTxtNombre(value)
                    dispatch(FiltrarMaestraProductosReducer(txtSegmento, value)); // Restaurar datos originales
                }}
                style={{ marginLeft: '4px', width: '150px' }}
                options={txtNombre.length + txtSegmento.length == 0
                    ? rex_filtro_data_maestra_nombre
                    : rex_filtro_data_maestra_nombre
                        .filter(rex => rex_data_maestra_productos
                            .find((ma) => ma.ship_sku ? ma.ship_sku.toUpperCase() == rex.value : false))}
            />

            <Table
                pagination={{
                    position: ["topRight", "none"],
                    pageSize: 15
                }}
                scroll={{
                    x: '1500px',
                    y: '430px',
                }}
                onRow={seleccionarFila}
                columns={columns}
                dataSource={rex_data_maestra_productos}
                className="Table-Home Modal-No-Homologados Row-Active"
                rowKey="id"
                rowClassName={rowClassName}
                size='small'
            />
        </Modal>
    )
}

export default ModalSkuNoHomologaciones