import React, { useEffect, useState, useRef } from 'react'
import { AsignarFiltrosMaestraProductosReducer, FiltrosDataMasterProductos, ObtenerMaestraProductosReducer } from '../../../Redux/Actions/Administracion/MaestraProductos/MaestraProductos'
import { Typography, Table, Card, Button, Popconfirm, Input, Tooltip, Pagination, Skeleton, Select, Space, DatePicker, Form } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import {
    FilterOutlined,
    EditOutlined,
    ReloadOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
    ArrowLeftOutlined,
    SaveOutlined
} from '@ant-design/icons'
import IconPaquete from '../../../Assets/Iconos/paquete.png'
import {
    ObtenerNoHomologadosReducer,
    ObtenerMasterProductosReducer,
    RegistrarHomologacionReducer,
    SelectProductsNoHomologacionReducer,
    ActualizarPaginaTablaNoHomologados,
    ActualizarFiltroOrdenNoHomologados,
    ActualizarFiltroInputNoHomologados,
    SaveMasterProductosReducer
} from '../../../Redux/Actions/Homologaciones/NoHomologados/NoHomologados'
import { Link, useNavigate } from 'react-router-dom'
import { ClearDataHomologadosNoHomologados } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'
import { funPermisosObtenidos } from '../../../Functions/funPermiso'
import { ObtenerMaestraPreciosReducer, ObtenerSellInReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/ObtenerData'
import ModalProductosSku from '../../../Components/Homologados/NoHomologados/ModalProductosSku'
import ModalSkuNoHomologaciones from '../../../Components/Homologados/NoHomologados/ModalSkuNoHomologados'
import { GetNoHomologadosReducer } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'

const NoHomologados = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography
    const { RangePicker } = DatePicker;

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [txtCodSku, settxtCodSku] = useState('')
    const [txtSku, settxtSku] = useState('')
    const [txtUrl, settxtUrl] = useState('')
    const [idSku, setidSku] = useState(0)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [showCheckBoxTable, setShowCheckBoxTable] = useState(true)
    const [filtrarInputActividad, setFiltrarInputActividad] = useState(false)
    const [showPopConfirmOtros, setShowPopConfirmOtros] = useState(false)
    const [confirmLoadingPopConfirm, setConfirmLoadingPopConfirm] = useState(false)
    const [abrirModalSku, setAbrirModalSku] = useState(null)
    const [visiblecolumns, setvisiblecolumns] = useState(true)
    const [cargandoNoHomologado, setCargandoNoHomologado] = useState(false)
    const [maxWidth, setMaxWidth] = useState(0)

    const {
        rex_data_maestra_productos_copia
    } = useSelector(({ maestraProductos }) => maestraProductos)
    const {
        rex_data_user_permissions
    } = useSelector(({ login }) => login)
    const {
        rex_data_no_homologados,
        rex_data_master_productos,
        rex_pagina_tabla_data_no_homologados,
        rex_filtro_orden_data_no_homologados,
        rex_filtro_input_data_no_homologados,
        rex_filtro_conexiones_no_homologados,
        rex_select_product_no_homologacion
    } = useSelector(({ noHomologados }) => noHomologados)
    const {
        rex_total_no_homologados,
        rex_cargando_data_no_homologados,
    } = useSelector(({ asignarNoHomologados }) => asignarNoHomologados)
    const {
        rex_data_no_homologados_clone,
        rex_get_data_hml,
        rex_search_text,
    } = useSelector(({ asignarNoHomologados }) => asignarNoHomologados)

    const getColumnSearchProps = (dataIndex, columnName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${columnName}`}
                    value={rex_filtro_input_data_no_homologados[dataIndex]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                        dispatch(ActualizarFiltroInputNoHomologados(dataIndex, e.target.value))
                        setFiltrarInputActividad(true)
                    }}
                    style={{ display: 'block' }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined
            style={{ color: rex_filtro_input_data_no_homologados[dataIndex]?.length > 0 ? 'blue' : 'gray' }}
        />),
    })
    const columnTitle = (nameColumn, nameColumOrden) => (
        <div
            style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', alignItems: 'center' }}
            onClick={async () => {
                let orden
                if (rex_filtro_orden_data_no_homologados.orden == 'asc') {
                    orden = 'desc'
                } else if (rex_filtro_orden_data_no_homologados.orden == 'desc') {
                    orden = null
                    nameColumOrden = 's_ytd'
                } else {
                    orden = 'asc'
                }
                dispatch(ActualizarFiltroOrdenNoHomologados(nameColumOrden, orden))
            }}
        >
            {
                nameColumOrden == 'index'
                    ? <div></div>
                    : null
            }
            <span title={nameColumn}>{nameColumn}</span>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <CaretUpOutlined
                    style={{
                        color:
                            rex_filtro_orden_data_no_homologados.column == nameColumOrden && rex_filtro_orden_data_no_homologados.orden == 'asc' ? 'blue' : 'gray',
                        fontSize: '9px'
                    }}
                />
                <CaretDownOutlined
                    style={{
                        color:
                            (rex_filtro_orden_data_no_homologados.column == nameColumOrden && rex_filtro_orden_data_no_homologados.orden == 'desc')
                                || (nameColumOrden == 's_ytd' && rex_filtro_orden_data_no_homologados.orden == null)
                                ? 'blue' : 'gray',
                        fontSize: '9px'
                    }}
                />
            </div>
        </div>
    )
    const columns = [
        {
            title: columnTitle('#', 'index'),
            dataIndex: 'id',
            fixed: 'left',
            render: (_, record) =>
                <div title={record.key ? record.key : '-'}>
                    {record.key ? record.key : '-'}
                </div>,
            width: '50px',
            align: 'center'
        },
        Table.SELECTION_COLUMN,
        {
            title: columnTitle('Cod_Proveedor', 'Cod_Proveedor'),
            dataIndex: 'Cod_Proveedor',
            fixed: 'left',
            ...getColumnSearchProps('Cod_Proveedor', 'Cod_Proveedor'),
            render: (_, record) => <div title={record.Cod_Proveedor}>{record.Cod_Proveedor}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Proveedor', 'Proveedor'),
            dataIndex: 'Proveedor',
            fixed: 'left',
            ...getColumnSearchProps('Proveedor', 'Proveedor'),
            render: (_, record) => <div title={record.Proveedor}>{record.Proveedor}</div>,
            width: '100px'
        },
        {
            title: columnTitle('País', 'Pais'),
            dataIndex: 'Pais',
            fixed: 'left',
            ...getColumnSearchProps('Pais', 'País'),
            render: (_, record) => <div title={record.Pais}>{record.Pais}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Web', 'Web'),
            dataIndex: 'Web',
            fixed: 'left',
            ...getColumnSearchProps('Web', 'Web'),
            render: (_, record) => <div title={record.Web}>{record.Web}</div>,
            width: '250px'
        },
        {
            title: columnTitle('ID_Producto', 'ID_Producto'),
            dataIndex: 'ID_Producto',
            ...getColumnSearchProps('ID_Producto', 'ID_Producto'),
            render: (_, record) => <div title={record.ID_Producto}>{record.ID_Producto}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Nombre', 'Nombre'),
            dataIndex: 'Nombre',
            ...getColumnSearchProps('Nombre', 'Nombre'),
            render: (_, record) => <div title={record.Nombre}>{record.Nombre}</div>,
            width: '180px'
        },
        {
            title: columnTitle('Material', 'Material'),
            dataIndex: 'Material',
            ...getColumnSearchProps('Material', 'Material'),
            render: (_, record) => <div title={record.Material}>{record.Material}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Marca', 'Marca'),
            dataIndex: 'Marca',
            ...getColumnSearchProps('Marca', 'Marca'),
            render: (_, record) => <div title={record.Marca}>{record.Marca}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Color', 'Color'),
            dataIndex: 'Color',
            ...getColumnSearchProps('Color', 'Color'),
            render: (_, record) => <div title={record.Color}>{record.Color}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Ancho', 'Ancho'),
            dataIndex: 'Ancho',
            ...getColumnSearchProps('Ancho', 'Ancho'),
            render: (_, record) => <div title={record.Ancho}>{record.Ancho}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Largo', 'Largo'),
            dataIndex: 'Largo',
            ...getColumnSearchProps('Largo', 'Largo'),
            render: (_, record) => <div title={record.Largo}>{record.Largo}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Medidas', 'Medidas'),
            dataIndex: 'Medidas',
            ...getColumnSearchProps('Medidas', 'Medidas'),
            render: (_, record) => <div title={record.Medidas}>{record.Medidas}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Look Diseño', 'Look_Disenio'),
            dataIndex: 'Look_Disenio',
            ...getColumnSearchProps('Look_Disenio', 'Look Diseño'),
            render: (_, record) => <div title={record.Look_Disenio}>{record.Look_Disenio}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Acabado', 'Acabado'),
            dataIndex: 'Acabado',
            ...getColumnSearchProps('Acabado', 'Acabado'),
            render: (_, record) => <div title={record.Acabado}>{record.Acabado}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Uso', 'Uso'),
            dataIndex: 'Uso',
            ...getColumnSearchProps('Uso', 'Uso'),
            render: (_, record) => <div title={record.Uso}>{record.Uso}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Tránsito', 'Transito'),
            dataIndex: 'Transito',
            ...getColumnSearchProps('Transito', 'Tránsito'),
            render: (_, record) => <div title={record.Transito}>{record.Transito}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Rendimiento x Caja', 'Rendimiento_X_Caja'),
            dataIndex: 'Rendimiento_X_Caja',
            ...getColumnSearchProps('Rendimiento_X_Caja', 'Rendimiento x Caja'),
            render: (_, record) => <div title={record.Rendimiento_X_Caja}>{record.Rendimiento_X_Caja}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Categoría', 'Categoria'),
            dataIndex: 'Categoria',
            ...getColumnSearchProps('Categoria', 'Categoría'),
            render: (_, record) => <div title={record.Categoria}>{record.Categoria}</div>,
            width: '100px'
        },
        {
            title: columnTitle('Sub Categoría', 'Sub_categoria'),
            dataIndex: 'Sub_categoria',
            ...getColumnSearchProps('Sub_categoria', 'Sub Categoría'),
            render: (_, record) => <div title={record.Sub_categoria}>{record.Sub_categoria}</div>,
            width: '100px'
        },
        ...Array.from({ length: 10 }, (_, i) => ({
            title: columnTitle(`Atr${i + 1}`, `Atr${i + 1}`),
            dataIndex: `Atr${i + 1}`,
            ...getColumnSearchProps(`Atr${i + 1}`, `Atr${i + 1}`),
            render: (_, record) => <div title={record[`Atr${i + 1}`]}>{record[`Atr${i + 1}`]}</div>,
            width: '100px'
        })),
        {
            title: columnTitle('Fecha', 'Fecha'),
            dataIndex: 'Fecha',
            ...getColumnSearchProps('Fecha', 'Fecha'),
            render: (_, record) => <div title={record.Fecha}>{record.Fecha}</div>,
            width: '100px',
            hidden: true,
        },
        {
            title: 'CodSKU',
            dataIndex: 'CodSkuSelect',
            width: '150px',
            editable: true,

            hidden: visiblecolumns,
        },
        {
            title: 'SKU',
            dataIndex: 'SkuSelect',
            width: '150px',
            editable: true,

            hidden: visiblecolumns,
        },
        {
            title: columnTitle('Url', 'Url'),
            dataIndex: 'Url',
            ...getColumnSearchProps('Url', 'Url'),
            width: '190px',
            editable: true,
            hidden: visiblecolumns,
        },
        {
            title: 'ACCIONES',
            dataIndex: 'actions',
            fixed: 'right',
            width: '70px',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
                        <Button onClick={() => {
                            save(record.id)
                            setvisiblecolumns(true);
                            settxtCodSku('');
                            settxtSku('');
                            dispatch(SaveMasterProductosReducer(rex_select_product_no_homologacion.Id, txtUrl, record.id))
                        }}>Guardar</Button>


                        <Tooltip title="Guardar" onClick={() => {
                            cancel();
                            setvisiblecolumns(true);
                            settxtCodSku('');
                            settxtSku('');
                        }}>
                            <Button
                                icon={<svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="close"
                                    width="1em"
                                    height="1em"
                                    fill="red"
                                    aria-hidden="true"
                                    style={{ margin: "0px 5px" }}
                                >
                                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>}
                                style={{ marginRight: 8 }}
                            />
                        </Tooltip>


                    </span>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
                        <Tooltip title="Editar Fila">
                            <EditOutlined
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                onClick={() => {
                                    edit(record);
                                    dispatch(SelectProductsNoHomologacionReducer(record));
                                    setvisiblecolumns(false)
                                }}
                            />
                        </Tooltip>



                    </div>
                );
            }
        },
    ]
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    }
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.id === editingKey;
    const edit = (record) => {
        form.setFieldsValue({ ...record, skuSelect: record.skuSelect || '' });
        setEditingKey(record.id);
    };
    const save = async (id) => {
        try {
            const row = await form.validateFields();
            // Lógica para actualizar data o enviar al backend
            setEditingKey('');
        } catch (err) {
        }
    };
    const cancel = () => {
        setEditingKey('');
    };
    const visibleColumns = columns.filter(col => !col.hidden);
    const mergedColumns = visibleColumns.map((col) => {
        if (!col.editable) return col;
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'skuSelect' ? 'select' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const EditableCell = ({ editing, dataIndex, title, inputType, record, children, ...restProps }) => {
        return (
            <td {...restProps}>
                {editing ? (
                    dataIndex === 'CodSkuSelect' || dataIndex === 'SkuSelect' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[{ required: true, message: `Por favor selecciona un SKU` }]}
                        >
                            <>
                                <div className="Container-Input">
                                    <Select
                                        placeholder={dataIndex === 'CodSkuSelect' ? 'Cod Sku' : 'Ship Sku'}
                                        className="Form-Item-Select"
                                        onClick={() => {
                                            if (abrirModalSku !== record.id) {
                                                setAbrirModalSku(record.id);
                                            }
                                        }}
                                        open={false}
                                        value={dataIndex === 'CodSkuSelect' ? rex_select_product_no_homologacion.Cod_Sku : rex_select_product_no_homologacion.Ship_Sku}
                                    />
                                    <div className="Text-Placeholder-Input-Bottom">Seleccionar</div>
                                </div>

                                {record.id === abrirModalSku && (
                                    <ModalSkuNoHomologaciones
                                        abrirModalSku={abrirModalSku}
                                        setAbrirModalSku={setAbrirModalSku}
                                        rex_data_master_productos={rex_data_master_productos}
                                        idRow={record.id}
                                        id_producto_so={record.id}
                                        producto_so_select={record}
                                        aceptar={() => { }}
                                    />
                                )}
                            </>
                        </Form.Item>
                    ) : dataIndex === 'Url' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[{ required: true, message: `Por favor ingresa ${title}` }]}
                            placeholder='texto'
                        >
                            <Input
                                value={txtUrl}
                                onChange={(value) => {
                                    settxtUrl(value)
                                }
                                }
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[{ required: true, message: `Por favor ingresa ${title}` }]}
                        >
                            <Input />
                        </Form.Item>
                    )
                ) : (
                    children
                )}
            </td>
        );
    };




    useEffect(() => {
        dispatch(ObtenerNoHomologadosReducer(true));
        dispatch(ObtenerMasterProductosReducer());
    }, []); // Se ejecuta solo una vez al montar el componente

    useEffect(() => {
        if (rex_data_master_productos.length > 0) {
            dispatch(FiltrosDataMasterProductos());
        }
    }, [rex_data_master_productos]);

    const dataWithKeys = rex_data_no_homologados.map((item, index) => ({
        ...item,
        key: index + 1, // Genera valores 1, 2, 3, ...
    }));
    return (
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card
                    title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                type='primary'
                                size='small'
                                ghost
                                onClick={() => navigate(-1)}
                                className="Button-Title-Module"
                            >
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">SKU (No Homologado)</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                        <div style={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
                            <Space>

                                <Button
                                    style={{ display: 'flex', alignItems: 'center' }} size='small'
                                    onClick={async () => {
                                        await dispatch(ObtenerNoHomologadosReducer(true))
                                        await dispatch(ObtenerMasterProductosReducer())
                                    }}
                                >
                                    <ReloadOutlined
                                        style={{ fontSize: '14px', marginRight: '4px', color: '#1677ff' }}
                                    />Recargar
                                </Button>
                                <RangePicker
                                    size="small"
                                    style={{ width: '360px' }}
                                    onChange={(dates, dateStrings) => {
                                        let value = dateStrings[0] && dateStrings[1] ? `${dateStrings[0]}|${dateStrings[1]}` : '';
                                        dispatch(ActualizarFiltroInputNoHomologados('desde', value));
                                    }}
                                />
                                {/* 
                                <Popconfirm
                                    title="Confirmar"
                                    description="Esta seguro de enviar los productos seleccionados a otros"
                                    open={showPopConfirmOtros}
                                    onConfirm={async () => {
                                        let products_selecteds = []
                                        rex_data_no_homologados.forEach(rex_data => {
                                            if (selectedRowKeys.includes(rex_data.id)) {
                                                products_selecteds.push(rex_data.id)
                                            }
                                        });
                                        await dispatch(RegistrarHomologacionReducer(0, 1, true, products_selecteds))
                                        setSelectedRowKeys([])
                                        setShowCheckBoxTable(false)
                                        setTimeout(() => {
                                            setShowCheckBoxTable(true)
                                        }, 100);
                                        setShowPopConfirmOtros(!showPopConfirmOtros)

                                    }}
                                    okButtonProps={{ loading: confirmLoadingPopConfirm }}
                                    onCancel={() => {
                                        setShowPopConfirmOtros(!showPopConfirmOtros)
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        disabled={!selectedRowKeys.length > 0}
                                        className="Button-CheckBox"
                                        onClick={() => {
                                            setShowPopConfirmOtros(!showPopConfirmOtros)
                                        }}
                                        size='small'
                                        style={{
                                            // marginBottom:'-20px',
                                            width: '90px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <span style={{ fontSize: '11px' }}>HML MASIVO {selectedRowKeys.length > 0 && `(${selectedRowKeys.length})`}</span>
                                    </Button>
                                </Popconfirm>
                               */}

                                {/* <Select
                                    showSearch
                                    allowClear
                                    placeholder="Filtro por responsable"
                                    onChange={(e) => {
                                        let value = e == undefined ? '' : e
                                        dispatch(ActualizarFiltroInputNoHomologados('responsable',value))
                                        ObtenerDataFiltroNoHomologado()
                                    }}
                                    value={
                                        rex_filtro_input_data_no_homologados.responsable
                                        ? rex_filtro_input_data_no_homologados.responsable
                                        : null
                                    }
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    size='small'
                                    style={{width: '200px'}}
                                    // options={rex_filtro_conexiones_no_homologados}
                                    options={[{label: 'Trade/IC', value: 'Trade/IC'},{ label: 'SAC', value:'SAC'}]}
                                /> */}
                            </Space>

                        </div>

                        {rex_cargando_data_no_homologados && rex_data_no_homologados.length == 0
                            ? <Skeleton.Input active={true} size='default' />
                            : <div style={{ display: 'flex', justifyContent: 'end', columnGap: '5px' }}>
                                <Pagination
                                    current={rex_pagina_tabla_data_no_homologados}
                                    total={rex_total_no_homologados}
                                    pageSize={20}
                                    onChange={async (page, pageSize) => {
                                        dispatch(ActualizarPaginaTablaNoHomologados(page))
                                    }}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_total_no_homologados)} registros</div>
                            </div>
                        }


                    </div>

                    {rex_cargando_data_no_homologados && rex_data_no_homologados.length == 0
                        ? <div style={{ marginTop: '10px' }}>
                            <Skeleton />
                            <Skeleton />
                        </div>
                        : showCheckBoxTable == true
                            ?
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                pagination={{
                                    position: ["none", "none"],
                                    pageSize: 20
                                }}
                                scroll={{
                                    y: '430px',
                                }}
                                rowSelection={showCheckBoxTable == true ? {
                                    type: 'checkbox',
                                    ...rowSelection,
                                } : {}}
                                columns={mergedColumns}
                                dataSource={dataWithKeys}
                                loading={rex_cargando_data_no_homologados}
                                className="Table-Home Table-Small"
                                size='small'
                            />
                            : null
                    }
                </Card>
            </Card>
        </div>
    )
}

export default NoHomologados