import config from '../../../config'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if (type === 'success') {
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    } else if (type === 'warning') {
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    } else if (type === 'error') {
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GenerarExcelReducer = (type, fecha) => async (dispatch) => {

    let url = ""
    let bodyJson = JSON.stringify({})
    if (type == "products-so" || type == "inventories" || type == "master-prices" || type == "products-approveds-nocombo" || type == "products-non-approveds") {
        bodyJson = JSON.stringify({
            "re_date": fecha
        })
    }
    await fetch(config.api_url + 'descargar-data/' + type,
        {
            mode: 'cors',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'usu_token': localStorage.getItem("usutoken"),
                'usutoken': localStorage.getItem("usutoken"),
            },
            body: bodyJson
        }
    )
        .then(async res => res.json())
        .then(async data => {
            if (data.respuesta) {
                url = data.data
                
            } else {
                notificacionServidor('error', data.message)
            }
        })
        .catch((error) => {
            console.log(error)
        })

    return url

}

export const RemoveExcelReducer = (archivo) => async (dispatch) => {
    // await fetch(config.api_url+'descargar-data/remove-excel',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept' : 'application/json',
    //             'Content-type' : 'application/json',
    //             'usu_token'	   : localStorage.getItem("usutoken"),
    //         },
    //         body: JSON.stringify({
    //             "re_url": archivo
    //         }),
    //     }
    // )
    // .then(res => res.json())
    // .then(async data => {
    //     if(data.response){
    //         notificacionServidor('success', data.message)
    //     }else{
    //         if(data.status == 400){
    //             notificacionServidor('warning', data.message)
    //         }else{
    //             notificacionServidor('error', data.message)
    //         }
    //     }
    // })
    // .catch((error)=> {
    //     console.log(error)
    // })
}