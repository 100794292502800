import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { Typography, Table, Card, Input, Button, Pagination, DatePicker, Skeleton, Select, Tooltip, Form } from 'antd'
import {
    FilterOutlined,
    ReloadOutlined,
    ArrowLeftOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    CalendarOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    SaveOutlined,
    EditOutlined
} from '@ant-design/icons'
import moment from 'moment'
import { format } from 'date-fns'
import {
    ObtenerMasterProductosReducer,
    SelectProductsNoHomologacionReducer
} from '../../../Redux/Actions/Homologaciones/NoHomologados/NoHomologados'
import IconPaquete from '../../../Assets/Iconos/paquete.png'
import {
    ObtenerHomologadosReducer,
    ActualizarPaginaTablaHomologados,
    ActualizarFiltroOrdenHomologados,
    ActualizarFiltroInputHomologados,
    MostrarOtrosHomologados
} from '../../../Redux/Actions/Homologaciones/Homologados/Homologados'
import { ClearDataHomologadosNoHomologados } from '../../../Redux/Actions/Homologaciones/NoHomologados/AsignarNoHomologados'
import { funPermisosObtenidos, funPermisosObtenidosBoolean } from '../../../Functions/funPermiso'
import '../../../Styles/Routes/Homologaciones/Homologados/Homologados.css'
import {

    SaveMasterProductosReducer
} from '../../../Redux/Actions/Homologaciones/NoHomologados/NoHomologados'
import ModalSkuNoHomologaciones from '../../../Components/Homologados/NoHomologados/ModalSkuNoHomologados'
const Homologados = (props) => {
    const collapsed = props.collapsed
    const { Title } = Typography
    const { RangePicker } = DatePicker;

    const [txtCodSku, settxtCodSku] = useState('')
    const [txtSku, settxtSku] = useState('')
    const [txtUrl, settxtUrl] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [visiblecolumns, setvisiblecolumns] = useState(true)
    const [abrirModalSku, setAbrirModalSku] = useState(null)

    const [firstRender, setFirstRender] = useState(false)

    const {
        rex_data_user_permissions
    } = useSelector(({ login }) => login)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={rex_filtro_input_data_homologados[dataIndex]}
                    disabled={rex_cargando_data_homologados ? true : false}
                    onChange={(e) => {
                        confirm({
                            closeDropdown: false,
                        })
                        setFirstRender(true)
                        dispatch(ActualizarFiltroInputHomologados(dataIndex, e.target.value))
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterOutlined
                style={{ color: rex_filtro_input_data_homologados[dataIndex]?.length > 0 ? 'blue' : 'gray' }}
            />
        ),
    })

    const getColumnDateProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{ padding: 8 }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <DatePicker
                    disabled={rex_cargando_data_homologados ? true : false}
                    allowClear={true}
                    onChange={(value) => {
                        setFirstRender(true)
                        dispatch(ActualizarFiltroInputHomologados(dataIndex, value))
                    }}
                />
            </div>
        ),
        filterIcon: () => (
            <CalendarOutlined
                style={{ color: rex_filtro_input_data_homologados[dataIndex] != null ? 'blue' : 'gray' }}
            />
        ),
    })

    const {
        rex_data_master_productos,
        rex_select_product_no_homologacion
    } = useSelector(({ noHomologados }) => noHomologados)

    const titleColumn = (columnName, column) => (
        <div
            className='Container-Title-Column-Approvals'
            onClick={() => {
                let orden
                if (rex_filtro_orden_data_homologados.orden == 'asc') {
                    orden = 'desc'
                } else if (rex_filtro_orden_data_homologados.orden == 'desc') {
                    orden = null
                } else {
                    orden = 'asc'
                }
                dispatch(ActualizarFiltroOrdenHomologados(columnName, orden))
            }}
        >
            {
                columnName == 'index'
                    ? <div></div>
                    : null
            }
            <span title={column}>{column}</span>
            <div>
                <CaretUpOutlined
                    className={`${rex_filtro_orden_data_homologados.orden == 'asc' && rex_filtro_orden_data_homologados.column == columnName ? 'Color-Icon-Blue' : 'Color-Icon-Gray'}`} />
                <CaretDownOutlined
                    className={`${rex_filtro_orden_data_homologados.orden == 'desc' && rex_filtro_orden_data_homologados.column == columnName ? 'Color-Icon-Blue' : 'Color-Icon-Gray'}`} />
            </div>
        </div>
    )

    const {
        rex_data_homologados,
        rex_data_cantidad_homologados,
        rex_cargando_data_homologados,
        rex_filtro_orden_data_homologados,
        rex_filtro_input_data_homologados,
        rex_pagina_tabla_data_homologados,
        rex_mostrar_otros_homologados,
        rex_filtro_conexiones_homologados
    } = useSelector(({ homologados }) => homologados)

    const ObtenerHomologados = async () => {
        await dispatch(ObtenerHomologadosReducer(true))
    }

    useEffect(() => {

        if (firstRender) {
            const timeOutInactividad = setTimeout(() => {
                ObtenerHomologados()
            }, 1000);

            return () => clearTimeout(timeOutInactividad);
        }
    }, [rex_filtro_input_data_homologados])




    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.id === editingKey;
    const edit = (record) => {
        form.setFieldsValue({ ...record, skuSelect: record.skuSelect || '' });
        setEditingKey(record.id);
    };
    const save = async (id) => {
        try {
            const row = await form.validateFields();
            // Lógica para actualizar data o enviar al backend
            setEditingKey('');
        } catch (err) {
        }
    };
    const cancel = () => {
        setEditingKey('');
    };

    const columnUpdateDate = {
        title: titleColumn('fecha_homologado', 'FEC. HML.'),
        dataIndex: 'fecha_homologado',
        ...getColumnDateProps('fecha_homologado', 'fecha homologado'),
        render: (_, record) =>
            <div title={record.fecha_homologado ? moment(record.fecha_homologado).format('DD/MM/YYYY h:mm a') : ''}>
                {record.fecha_homologado ? moment(record.fecha_homologado).format('DD/MM/YYYY h:mm a') : ''}
            </div>,
        width: '130px',
        align: 'center'
    }

    const columnPersonUpdate = {
        title: titleColumn('usuusuario', 'USUARIO'),
        dataIndex: 'usuusuario',
        ...getColumnSearchProps('usuusuario', 'usuario'),
        render: (_, record) => <div title={record.usuusuarios ? record.usuusuarios.usuusuario : ''}>
            {record.usuusuarios ? record.usuusuarios.usuusuario : ''}
        </div>,
        width: '150px'
    }

    const columns = [
        {
            title: titleColumn('key', '#'),
            dataIndex: 'key',
            render: (_, record) =>
                <div title={record.key ? record.key : '-'}>
                    {record.key ? record.key : '-'}
                </div>,
            width: '50px',
            fixed: 'left',
            align: 'center'
        },
        {
            title: titleColumn('Cod_Proveedor', 'Cod_Proveedor'),
            dataIndex: 'Cod_Proveedor',
            fixed: 'left',
            ...getColumnSearchProps('Cod_Proveedor', 'Cod_Proveedor'),
            render: (_, record) => <div title={record.Cod_Proveedor}>{record.Cod_Proveedor}</div>,
            width: '100px'
        },
        {
            title: titleColumn('Proveedor', 'Proveedor'),
            dataIndex: 'Proveedor',
            fixed: 'left',
            ...getColumnSearchProps('Proveedor', 'Proveedor'),
            render: (_, record) => <div title={record.Proveedor}>{record.Proveedor}</div>,
            width: '100px'
        },
        {
            title: titleColumn('País', 'Pais'),
            dataIndex: 'Pais',
            fixed: 'left',
            ...getColumnSearchProps('Pais', 'País'),
            render: (_, record) => <div title={record.Pais}>{record.Pais}</div>,
            width: '100px'
        },
        {
            title: titleColumn('Web', 'Web'),
            dataIndex: 'Web',
            fixed: 'left',
            ...getColumnSearchProps('Web', 'Web'),
            render: (_, record) => <div title={record.Web}>{record.Web}</div>,
            width: '250px'
        },
        {
            title: titleColumn('ID_Producto', 'ID_Producto'),
            dataIndex: 'ID_Producto',
            ...getColumnSearchProps('ID_Producto', 'ID_Producto'),
            render: (_, record) => <div title={record.ID_Producto}>{record.ID_Producto}</div>,
            width: '100px'
        },
        {
            title: titleColumn('Nombre', 'Nombre'),
            dataIndex: 'Nombre',
            ...getColumnSearchProps('Nombre', 'Nombre'),
            render: (_, record) => <div title={record.Nombre}>{record.Nombre}</div>,
            width: '180px'
        },
        {
            title: titleColumn('Material', 'Material'),
            dataIndex: 'Material',
            ...getColumnSearchProps('Material', 'Material'),
            render: (_, record) => <div title={record.Material}>{record.Material}</div>,
            width: '100px'
        },
        {
            title: titleColumn('Marca', 'Marca'),
            dataIndex: 'Marca',
            ...getColumnSearchProps('Marca', 'Marca'),
            render: (_, record) => <div title={record.Marca}>{record.Marca}</div>,
            width: '100px'
        },
        {
            title: titleColumn('Color', 'Color'),
            dataIndex: 'Color',
            ...getColumnSearchProps('Color', 'Color'),
            render: (_, record) => <div title={record.Color}>{record.Color}</div>,
            width: '100px'
        },
        {
            title: titleColumn('Ancho', 'Ancho'),
            dataIndex: 'Ancho',
            ...getColumnSearchProps('Ancho', 'Ancho'),
            render: (_, record) => <div title={record.Ancho}>{record.Ancho}</div>,
            width: '100px'
        },
        {
            title: titleColumn('Largo', 'Largo'),
            dataIndex: 'Largo',
            ...getColumnSearchProps('Largo', 'Largo'),
            render: (_, record) => <div title={record.Largo}>{record.Largo}</div>,
            width: '100px'
        },
        {
            title: titleColumn('Medidas', 'Medidas'),
            dataIndex: 'Medidas',
            ...getColumnSearchProps('Medidas', 'Medidas'),
            render: (_, record) => <div title={record.Medidas}>{record.Medidas}</div>,
            width: '100px'
        },
        {
            title: 'CodSKU',
            dataIndex: 'CodSkuSelect',
            width: '150px',
            editable: true,

            hidden: visiblecolumns,
        },
        {
            title: 'SKU',
            dataIndex: 'SkuSelect',
            width: '150px',
            editable: true,

            hidden: visiblecolumns,
        },
        {
            title: titleColumn('Url', 'Url'),
            dataIndex: 'Url',
            ...getColumnSearchProps('Url', 'Url'),
            width: '190px',
            editable: true,
            hidden: visiblecolumns,
        },
        {
            title: 'ACCIONES',
            dataIndex: 'actions',
            fixed: 'right',
            width: '70px',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
                        <Button onClick={() => {
                            save(record.id)
                            setvisiblecolumns(true);
                            settxtCodSku('');
                            settxtSku('');
                            dispatch(SaveMasterProductosReducer(rex_select_product_no_homologacion.id, txtUrl, record.id))
                        }}>Guardar</Button>


                        <Tooltip title="Guardar" onClick={() => {
                            cancel();
                            setvisiblecolumns(true);
                            settxtCodSku('');
                            settxtSku('');
                        }}>
                            <Button
                                icon={<svg
                                    viewBox="64 64 896 896"
                                    focusable="false"
                                    data-icon="close"
                                    width="1em"
                                    height="1em"
                                    fill="red"
                                    aria-hidden="true"
                                    style={{ margin: "0px 5px" }}
                                >
                                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>}
                                style={{ marginRight: 8 }}
                            />
                        </Tooltip>


                    </span>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
                        <Tooltip title="Editar Fila">
                            <EditOutlined
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                onClick={() => {
                                    edit(record);
                                    dispatch(SelectProductsNoHomologacionReducer(record));
                                    setvisiblecolumns(false)
                                }}
                            />
                        </Tooltip>



                    </div>
                );
            }
        }
    ]
    const visibleColumns = columns.filter(col => !col.hidden);

    const mergedColumns = visibleColumns.map((col) => {
        if (!col.editable) return col;
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'skuSelect' ? 'select' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });
    const ObtenerDataHomologados = async () => {
        if (rex_data_master_productos.length == 0) {
            await dispatch(ObtenerMasterProductosReducer())
        }
        if (rex_data_homologados.length == 0) {
            await dispatch(ObtenerHomologadosReducer(true))
        }
        await dispatch(ClearDataHomologadosNoHomologados())
    }

    useEffect(() => {
        ObtenerDataHomologados()
    }, [])
    const EditableCell = ({ editing, dataIndex, title, inputType, record, children, ...restProps }) => {
        return (
            <td {...restProps}>
                {editing ? (
                    dataIndex === 'CodSkuSelect' || dataIndex === 'SkuSelect' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[{ required: true, message: `Por favor selecciona un SKU` }]}
                        >
                            <>
                                <div className="Container-Input">
                                    <Select
                                        placeholder={dataIndex === 'CodSkuSelect' ? 'Cod Sku' : 'Ship Sku'}
                                        className="Form-Item-Select"
                                        onClick={() => {
                                            if (abrirModalSku !== record.id) {
                                                setAbrirModalSku(record.id);
                                            }
                                        }}
                                        open={false}
                                        value={dataIndex === 'CodSkuSelect' ? rex_select_product_no_homologacion.cod_sku : rex_select_product_no_homologacion.ship_sku}
                                    />
                                    <div className="Text-Placeholder-Input-Bottom">Seleccionar</div>
                                </div>

                                {record.id === abrirModalSku && (
                                    <ModalSkuNoHomologaciones
                                        abrirModalSku={abrirModalSku}
                                        setAbrirModalSku={setAbrirModalSku}
                                        rex_data_master_productos={rex_data_master_productos}
                                        idRow={record.id}
                                        id_producto_so={record.id}
                                        producto_so_select={record}
                                        aceptar={() => { }}
                                        settxtUrl={settxtUrl}
                                    />
                                )}
                            </>
                        </Form.Item>
                    ) : dataIndex === 'Url' ? (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[{ required: true, message: `Por favor ingresa ${title}` }]}
                        >
                            <Input
                                value={txtUrl}
                                onChange={e => settxtUrl(e.target.value)}
                            />
                        </Form.Item>
                    ) : (
                        <Form.Item
                            name={dataIndex}
                            style={{ margin: 0 }}
                            rules={[{ required: true, message: `Por favor ingresa ${title}` }]}
                        >
                            <Input />
                        </Form.Item>
                    )
                ) : (
                    children
                )}
            </td>
        );
    };


    const dataWithKeys = rex_data_homologados.map((item, index) => ({
        ...item,
        key: index + 1, // Genera valores 1, 2, 3, ...
    }));
    return (
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card className="Card-Container">
                <Card
                    title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                type='primary'
                                size='small'
                                ghost
                                onClick={() => navigate(-1)} className="Button-Title-Module"
                            >
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">SKU (Homologado)</Title>

                        </div>
                    }
                    className="Card-Item"
                >
                    <div className='Container-Action-Info-Table'>
                        <div style={{ display: 'flex' }}>
                            <Button
                                style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }} size='small'
                                onClick={async () => {
                                    await dispatch(ObtenerMasterProductosReducer())
                                    await dispatch(ObtenerHomologadosReducer(true))
                                }}
                            >
                                <ReloadOutlined
                                    className='Button-Action-Reload'
                                />Recargar
                            </Button>

                            <RangePicker
                                size="small"
                                style={{ width: '360px' }}

                            />
                            {/* <Button
                                onClick={() => dispatch(MostrarOtrosHomologados(!rex_mostrar_otros_homologados))}
                                style={{display:'flex', alignItems:'center', marginRight:'10px'}} size='small'
                            >
                               {rex_mostrar_otros_homologados ? <EyeOutlined /> : <EyeInvisibleOutlined />} Otros
                            </Button> */}

                        </div>
                        {rex_cargando_data_homologados && rex_data_homologados.length == 0
                            ? <Skeleton.Input active={true} size='default' />
                            : <div style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
                                <Pagination
                                    current={rex_pagina_tabla_data_homologados}
                                    total={rex_data_cantidad_homologados}
                                    pageSize={20}
                                    onChange={async (page, pageSize) => {
                                        dispatch(ActualizarPaginaTablaHomologados(page))
                                    }}
                                    size='small'
                                />
                                <div>Total: {Intl.NumberFormat('en-IN').format(rex_data_cantidad_homologados)} registros</div>
                            </div>
                        }

                    </div>

                    {rex_cargando_data_homologados && rex_data_homologados.length == 0
                        ? <div style={{ marginTop: '10px' }}>
                            <Skeleton />
                            <Skeleton />
                        </div>
                        : <>
                            <Table
                                components={{
                                    body: {
                                        cell: EditableCell,
                                    },
                                }}
                                pagination={{
                                    position: ["none", "none"],
                                    pageSize: 20
                                }}
                                scroll={{
                                    y: '450px',
                                    x: '500px'
                                }}
                                columns={mergedColumns}
                                loading={rex_cargando_data_homologados}
                                dataSource={dataWithKeys}
                                className="Table-Home"
                                size='small'
                            />
                        </>
                    }
                </Card>
            </Card>
        </div>
    )
}

export default Homologados