import React, { useState } from 'react'
import { Card, Row, Col, Typography, Button, DatePicker, Space, } from 'antd'
import {
    DownloadOutlined,
    ArrowLeftOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import {
    GenerarExcelReducer,
    // RemoveExcelReducer,
} from '../../Redux/Actions/DownloadData/DownloadData'
import IconoCalendario from '../../Assets/Img/Comunes/calendario.png'
import { format } from 'date-fns'
import dayjs from 'dayjs'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { funPermisosObtenidos } from '../../Functions/funPermiso'
import esES from "antd/es/locale/es_ES";
import "dayjs/locale/es";
dayjs.locale("es");

const DownloadData = (props) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const collapsed = props.collapsed
    const { Title } = Typography
    const { RangePicker } = DatePicker;

    const [loadingDownload, setLoadingDownload] = useState(false)
    const [dateDownloadData, setDateDownloadData] = useState(format(new Date(), "MM/yyyy"))
    const [buttonFeatures, setButtonFeatures] = useState(() => {
        const permisos = localStorage.getItem('tpuprivilegio') == "todo"
        return permisos
            ? {
                linkFile: "products-approveds",
                nombreFile: "Master Productos Homologados.xlsx",
                deleteFile: "products-approveds",
            }
            : {
                linkFile: "products-approveds",
                nombreFile: "Master Productos Homologados.xlsx",
                deleteFile: "products-approveds",
            }
    })

    const {
        rex_data_user_permissions
    } = useSelector(({ login }) => login)


    const handleDownload = (link1, name) => {
        const link = document.createElement("a");
        link.href = link1; // Ruta del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div
            style={{
                display: 'flex',
                width: !collapsed ? 'calc(100% - 180px)' : 'calc(100% - 80px)',
                transition: 'width .7s'
            }}
        >
            <Card
                className="Card-Container Card-Container-CargarData"
                style={{
                    height: "100%",
                    display: "flex",
                    width: '100%',
                    position: 'relative'
                }}
            >
                <Card
                    title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button type='primary' size='small' ghost onClick={() => navigate(-1)} className="Button-Title-Module">
                                <ArrowLeftOutlined />
                            </Button>
                            <Title className="Title-Module">Descargar Data</Title>
                        </div>
                    }
                    className="Card-Item"
                >
                    <div>
                        <DatePicker
                            picker="month"
                            format={"MM/YYYY"}
                            locale={esES}
                            onChange={(_, dateString) => setDateDownloadData(dateString)}
                        />
                    </div>
                    <div style={{ display: "flex", gap: "5px", alignItems: "center", alignContent: "center" }}>
                        <Row
                            style={{
                                marginRight: '5px',
                            }}
                        >
                            <Col xl={24} style={{ marginBottom: '10px', display: 'flex', columnGap: '15px' }}>

                                {/* {
                            funPermisosObtenidos(
                                rex_data_user_permissions,
                                'download.data.reseterar.excel',
                                <Button
                                    style={{
                                        display:'flex', 
                                        alignItems:'center', 
                                        marginRight:'10px'
                                    }} 
                                    onClick={() => dispatch(RemoveExcelReducer(buttonFeatures.deleteFile))}
                                    size='small'
                                >
                                    <ReloadOutlined className='Button-Action-Reload' />
                                    Resetear Excel
                                </Button>
                            )
                        } */}

                                {
                                    buttonFeatures.linkFile == "products-so" || buttonFeatures.linkFile == "inventories" || buttonFeatures.linkFile == "master-prices"
                                        ? <div>
                                            <img src={IconoCalendario} style={{ marginRight: '5px', width: '20px', height: '20px' }} />
                                            <span className='Text-Filter-Date-Status-Top'>Fecha</span>
                                            <DatePicker
                                                size='small'
                                                placeholder='Fecha'
                                                className='Date-Picker-Status-Top'
                                                picker="month"
                                                inputReadOnly={true}
                                                format={['MM-YYYY']}
                                                defaultValue={dayjs()}
                                                onChange={(e) => {
                                                    if (e) {
                                                        const mes = e.$M + 1
                                                        const date = mes < 10 ? "0" + mes + "/" + e.$y : mes + "/" + e.$y
                                                        setDateDownloadData(date)
                                                    } else {
                                                        setDateDownloadData(format(new Date(), "MM/yyyy"))
                                                    }
                                                }}
                                            />


                                        </div>
                                        : null
                                }
                            </Col>
                            <Col xl={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '20px',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {/* {
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.ventas',
                                        <Button
                                            style={{
                                                color: buttonFeatures.linkFile == "products-so" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "products-so" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "products-so",
                                                    nombreFile: "Productos Homologados.xlsx",
                                                    deleteFile: "products-so",
                                                })
                                            }
                                        >
                                            Ventas
                                        </Button>
                                    )
                                } */}

                                    {/*
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.productos.hml.combo',
                                        <Button
                                            style={{
                                                color: buttonFeatures.linkFile == "products-approveds-combo" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "products-approveds-combo" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "products-approveds-combo",
                                                    nombreFile: "Master Productos Homologados.xlsx",
                                                    deleteFile: "products-approveds-combo",
                                                })
                                            }
                                        >
                                            Productos Hml Combo
                                        </Button>
                                    )
                                 */
                                    }

                                    {/*
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.productos.hml.nocombo',
                                        <Button
                                            style={{
                                                color: buttonFeatures.linkFile == "products-approveds-nocombo" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "products-approveds-nocombo" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "products-approveds-nocombo",
                                                    nombreFile: "Master Productos Homologados No Combo.xlsx",
                                                    deleteFile: "products-approveds-nocombo",
                                                })
                                            }
                                        >
                                            Productos Hml No Combo
                                        </Button>
                                    )
                                        */
                                    }
                                    {
                                        funPermisosObtenidos(
                                            rex_data_user_permissions,
                                            'download.data.productos.no.hml',
                                            <Button
                                                style={{
                                                    color: buttonFeatures.linkFile == "products-approveds-nocombo" ? '#fff' : '',
                                                    backgroundColor: buttonFeatures.linkFile == "products-approveds-nocombo" ? '#1677ff' : ''
                                                }}
                                                shape="round"
                                                onClick={() =>
                                                    setButtonFeatures({
                                                        linkFile: "products-approveds-nocombo",
                                                        nombreFile: "Master Productos No Homologados.xlsx",
                                                        deleteFile: "products-approveds-nocombo",
                                                    })
                                                }
                                            >
                                                Productos Homologados
                                            </Button>
                                        )
                                    }
                                    {
                                        funPermisosObtenidos(
                                            rex_data_user_permissions,
                                            'download.data.productos.no.hml',
                                            <Button
                                                style={{
                                                    color: buttonFeatures.linkFile == "products-non-approveds" ? '#fff' : '',
                                                    backgroundColor: buttonFeatures.linkFile == "products-non-approveds" ? '#1677ff' : ''
                                                }}
                                                shape="round"
                                                onClick={() =>
                                                    setButtonFeatures({
                                                        linkFile: "products-non-approveds",
                                                        nombreFile: "Master Productos No Homologados.xlsx",
                                                        deleteFile: "products-non-approveds",
                                                    })
                                                }
                                            >
                                                Productos No Homologados
                                            </Button>
                                        )
                                    }
                                    {
                                        funPermisosObtenidos(
                                            rex_data_user_permissions,
                                            'download.data.master.productos',
                                            <Button
                                                style={{
                                                    color: buttonFeatures.linkFile == "master-products" ? '#fff' : '',
                                                    backgroundColor: buttonFeatures.linkFile == "master-products" ? '#1677ff' : ''
                                                }}
                                                shape="round"
                                                onClick={() =>
                                                    setButtonFeatures({
                                                        linkFile: "master-products",
                                                        nombreFile: "Master Productos.xlsx",
                                                        deleteFile: "master-products",
                                                    })
                                                }
                                            >
                                                Master Productos
                                            </Button>
                                        )
                                    }
                                    {
                                        funPermisosObtenidos(
                                            rex_data_user_permissions,
                                            'download.data.master.clientes',
                                            <Button
                                                style={{
                                                    color: buttonFeatures.linkFile == "master-clients" ? '#fff' : '',
                                                    backgroundColor: buttonFeatures.linkFile == "master-clients" ? '#1677ff' : ''
                                                }}
                                                shape="round"
                                                onClick={() =>
                                                    setButtonFeatures({
                                                        linkFile: "master-clients",
                                                        nombreFile: "Master Clientes.xlsx",
                                                        deleteFile: "master-clients",
                                                    })
                                                }
                                            >
                                                Master Clientes
                                            </Button>
                                        )
                                    }

                                    {/*
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.master.clientes',
                                        <Button
                                            style={{
                                                color: buttonFeatures.linkFile == "master-clients" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "master-clients" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "master-clients",
                                                    nombreFile: "Master Clientes.xlsx",
                                                    deleteFile: "master-clients",
                                                })
                                            }
                                        >
                                            Master Clientes
                                        </Button>
                                    )
                                        */
                                    }

                                    {/*
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.master.productos',
                                        <Button
                                            style={{
                                                color: buttonFeatures.linkFile == "master-products" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "master-products" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "master-products",
                                                    nombreFile: "Master Productos.xlsx",
                                                    deleteFile: "master-products",
                                                })
                                            }
                                        >
                                            Master Productos
                                        </Button>
                                    )
                                        */
                                    }

                                    {/*
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.prices',
                                        <Button
                                            style={{
                                                color: buttonFeatures.linkFile == "master-prices" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "master-prices" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "master-prices",
                                                    nombreFile: "Master Precios.xlsx",
                                                    deleteFile: "master-prices",
                                                })
                                            }
                                        >
                                            Master Precios
                                        </Button>
                                    )
                                        */
                                    }

                                    {/* {
                                    funPermisosObtenidos(
                                        rex_data_user_permissions,
                                        'download.data.inventario',
                                        <Button
                                            style={{
                                                color: buttonFeatures.linkFile == "inventories" ? '#fff' : '',
                                                backgroundColor: buttonFeatures.linkFile == "inventories" ? '#1677ff' : ''
                                            }}
                                            shape="round"
                                            onClick={() => 
                                                setButtonFeatures({
                                                    linkFile: "inventories",
                                                    nombreFile: "Inventarios.xlsx",
                                                    deleteFile: "inventories",
                                                })
                                            }
                                        >
                                            Inventario
                                        </Button>
                                    )
                                } */}

                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: "8px" }}>
                        <Button
                            shape="round"
                            onClick={async () => {
                                setLoadingDownload(true)
                                const fileUrl = await dispatch(GenerarExcelReducer(buttonFeatures.linkFile, dateDownloadData))
                                console.log('url' + fileUrl)
                                if (fileUrl) {
                                    const link = document.createElement('a')
                                    link.href = fileUrl
                                    link.download = buttonFeatures.nombreFile
                                    link.click()
                                }
                                setLoadingDownload(false)
                            }}
                            loading={loadingDownload}
                        >
                            Descargar <DownloadOutlined />
                        </Button>
                    </div>
                </Card>
            </Card>
        </div>
    )
}

export default DownloadData