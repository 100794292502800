import config from '../../../../config'
import {
    OBTENER_DATA_MAESTRA_PRODUCTOS,
    OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
    FILTRO_DATA_MAESTRA_PRODUCTOS_CODIGO,
    FILTRO_DATA_MAESTRA_PRODUCTOS_NOMBRE,
    FILTRO_DATA_MAESTRA_PRODUCTOS_CATEGORIA,
    CARGANDO_DATA_MAESTRA_PRODUCTOS
} from '../../../../Constans/Administracion/MaestraProductos/MaestraProductos'
import { OBTENER_DATA_MASTER_PRODUCTOS } from '../../../../Constans/Homologaciones/NoHomologados/NoHomologados'

export const ObtenerMaestraProductosReducer = () => async (dispatch) => {

    dispatch({
        type: CARGANDO_DATA_MAESTRA_PRODUCTOS,
        payload: true
    })

    await fetch(config.api_url + 'administration/get-products',
        {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'usu_token': localStorage.getItem("usutoken"),
            },
        }
    )
        .then(res => res.json())
        .then(data => {

            dispatch({
                type: OBTENER_DATA_MAESTRA_PRODUCTOS,
                payload: data.data
            })
            dispatch({
                type: OBTENER_DATA_MAESTRA_PRODUCTOS_COPIA,
                payload: data.data
            })
        }).catch((error) => {
            console.log(error)
        })

    dispatch({
        type: CARGANDO_DATA_MAESTRA_PRODUCTOS,
        payload: false
    })
}

export const FiltrarMaestraProductosReducer = (codigo, nombre) => async (dispatch, getState) => {

    let lista_ma_pro_orig = getState().maestraProductos.rex_data_maestra_productos_copia
    let mae_pro_filtrado

    //Cuando se hace clear en el select
    if (codigo == undefined) {
        codigo = ''
    }
    if (nombre == undefined) {
        nombre = ''
    }


    mae_pro_filtrado = lista_ma_pro_orig.filter((pro) =>
    (
        (pro.Cod_Sku ? pro.Cod_Sku.toLowerCase().includes(codigo?.toLocaleLowerCase()) : codigo != '' ? false : true)
        && (pro.Ship_Sku ? pro.Ship_Sku.toLowerCase().includes(nombre?.toLocaleLowerCase()) : nombre != '' ? false : true)
    )
    )

    if (nombre == '' && codigo == '') {
        dispatch({
            type: OBTENER_DATA_MASTER_PRODUCTOS,
            payload: lista_ma_pro_orig
        })
    } else {
        dispatch({
            type: OBTENER_DATA_MASTER_PRODUCTOS,
            payload: mae_pro_filtrado
        })
    }
}

export const AsignarFiltrosMaestraProductosReducer = () => async (dispatch, getState) => {

    let data = getState().noHomologados.rex_data_master_productos

    let filtro_codigo = []
    let filtro_nombre = []

    data.forEach(dat => {
        if (dat.cod_sku) {
            let existe_codigo = filtro_codigo.findIndex((filtro) => filtro.value == dat.cod_sku.toUpperCase())
            if (existe_codigo == -1) {
                filtro_codigo.push({
                    label: dat.cod_sku.toUpperCase(),
                    value: dat.cod_sku.toUpperCase()
                })
            }
        }
        if (dat.ship_sku) {
            let existe_nombre = filtro_nombre.findIndex((filtro) => filtro.value == dat.ship_sku.toUpperCase())
            if (existe_nombre == -1) {
                filtro_nombre.push({
                    label: dat.ship_sku.toUpperCase(),
                    value: dat.ship_sku.toUpperCase()
                })
            }
        }

    })

    dispatch({
        type: FILTRO_DATA_MAESTRA_PRODUCTOS_CODIGO,
        payload: filtro_codigo
    })
    dispatch({
        type: FILTRO_DATA_MAESTRA_PRODUCTOS_NOMBRE,
        payload: filtro_nombre
    })

}

export const FiltrosDataMasterProductos = () => async (dispatch, getState) => {
    let data = getState().noHomologados.rex_data_master_productos

    let filtro_codigo = []
    let filtro_nombre = []
    data.forEach(dat => {
        if (dat.Cod_Sku) {
            let existe_codigo = filtro_codigo.findIndex((filtro) => filtro.value == dat.Cod_Sku.toUpperCase())
            if (existe_codigo == -1) {
                filtro_codigo.push({
                    label: dat.Cod_Sku.toUpperCase(),
                    value: dat.Cod_Sku.toUpperCase()
                })
            }
        }
        if (dat.Ship_Sku) {
            let existe_nombre = filtro_nombre.findIndex((filtro) => filtro.value == dat.Ship_Sku.toUpperCase())
            if (existe_nombre == -1) {
                filtro_nombre.push({
                    label: dat.Ship_Sku.toUpperCase(),
                    value: dat.Ship_Sku.toUpperCase()
                })
            }
        }

    })

    dispatch({
        type: FILTRO_DATA_MAESTRA_PRODUCTOS_CODIGO,
        payload: filtro_codigo
    })
    dispatch({
        type: FILTRO_DATA_MAESTRA_PRODUCTOS_NOMBRE,
        payload: filtro_nombre
    })
}